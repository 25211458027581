<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" class="mb-0" :label="inputLabel" :label-size="size">
        <div class="array-spaced" v-for="(itm, index) in inputValue" :key="itm + '' + index">
            <component
                v-if="!readOnly"
                :is="componentName"
                :dataName="dataName"
                :inputLabel="inputLabel + ' ' + index"
                :index="index"
                :value="itm"
                :size="size"
                :required="required"
                :readOnly="readOnly"
                appendComponent='btn-remove'
                :parentFormID="parentFormID"
            ></component>
            <component 
                v-else
                :is="componentName"
                :dataName="dataName"
                :inputLabel="inputLabel + ' ' + index"
                :index="index"
                :value="itm"
                :size="size"
                :required="required"
                :readOnly="readOnly"
                :parentFormID="parentFormID"
            ></component>
        </div>
        <component 
            v-if="!readOnly"
            :is="componentName"
            :dataName="dataName"
            :inputLabel="'Add New: ' + inputLabel"
            :index="-2"
            :size="size"
            appendComponent='btn-add'
            :parentFormID="parentFormID"
        >
        </component>
    </b-form-group>
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
//import { ValidationBus } from '@/formArrayBus.js';

import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
// GPS Not Currently Compatible
//import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'


export default {
    name: 'formarrayengine',
     components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        //'gps-input': GPSInput, GPS not compatible
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput
    },
    props:{
        bus: Object,
        dataName: String,
        inputLabel: String,
        componentName: String,
        parentFormID: String,
        value: {
            type: Array,
            default: () => {return []}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
    },
    data(){
        return{
            //arrayEngineBus: FormArrayBus,
            //anotherBus: ValidationBus,
            inputValue: [],
            originalValue: [],
            isDirty: false
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('setDirty', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, value: undefined, valid: this.validationResult.all, isDirty: this.isDirty} );
        },
    },
    computed: {
        // a computed getter
        validationResult: function () {
            var requiredRes = ( (this.required && this.inputValue.length == 0) ? false : true) ;

            return {requirementFilled: requiredRes, all: (requiredRes)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return ''
            else if(!res.requirementFilled)
                return 'Required'
            else if(!res.regexMatches)
                return 'Invalid Entry: Input Should Match ' + this.validator
            else
                return 'OK'
        },
        childDataNames: function(){
            var names = [];
            for (let i = 0; i < this.inputValue.length; i++) {
                names.push(this.dataName + '-_-' + i);
            }
            // And The New One
            names.push(this.dataName + '-_-new');
            return names;
        }
    },
    mounted(){
        // setTimeout(()=>{ this.inputValue.push("5551234567");},3000)
        // Listen For Append Changes
        this.$store.subscribe((mutation, state) => {
            if( (mutation.type === 'formAppend' || mutation.type === 'formPrepend') && mutation.payload.parentFormID == this.parentFormID && mutation.payload.dataName == this.dataName){
               // Likely My Child, Check It
               console.log("Form Array Got Mutation For Own Child");
                var ind = mutation.payload.index;
                var isAppend = (mutation.type === 'formAppend') ? true : false;

                // Do Actual Action
                if(isAppend && ind == -2){ // Adding Item
                    var newVal = this.$store.getters.getFormValue({parentFormID: this.parentFormID, dataName: this.dataName, index: -2});
                    this.inputValue.push(newVal);
                    // Set Dirty Bit
                    this.touch();
                }else if(isAppend){ // Removing Item
                    //this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName} );
                    console.log(`Removing Item ${ind} From ${this.dataName} Array`)
                    this.inputValue.splice(ind, 1)
                    // this.inputValue = this.$store.getters.getFormValue({parentFormID: this.parentFormID, dataName: this.dataName});
                    // this.inputValue = this.inputValue.splice(ind, 1);
                    // Remove This Item From The State
                    // this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: mutation.payload.dataName});
                    //console.log("Moving On")
                    // Pull Values From State, And Apply To Our Array
                    //arrayVals = this.$store.getters.getFormValue({parentFormID: this.parentFormID, dataName: this.dataName});
                    //console.log(arrayVals);
                    // Map Object Array To Value Array
                    //var rawValues = arrayVals.map(x => x.value);
                    // Set Current Values To Array Values From State
                    //this.inputValue = rawValues;
                    // Set Dirty Bit
                    this.touch();
                }else{
                    // TODO: whatever other actions exist
                }

            }
        })
    },
    created(){

    },
    beforeDestroy(){
        // this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName} );
    },
    beforeMount (){
        // this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.value, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.originalValue = cloneDeep(this.value);
        if(this.value != null){
            this.inputValue = this.value;
        }else{
            console.log("Form Array Input Is Null")
            this.inputValue = [];
        }
        
    },
}
</script>

<style>

</style>


