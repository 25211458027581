<template>
    <div>
        <b-row v-if="$store.getters.checkUIPC({path: ['eventList', 'access'] })">
            <b-col xs="12" lg="2" xl="2" class="">
                <!-- Actions -->
                <h5>Actions</h5>
                <b-row no-gutters>
                    <b-col sm="12" md="6" lg="12"  v-if="showTestBtn" @click="buttonPressed('addtest')" class="mt-2">
                        <b-button block variant="primary" v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'addTest'] })">Enter Work Order</b-button>
                    </b-col>
                    <b-col sm="12" md="6" lg="12" v-if="showInstallBtn" @click="buttonPressed('addinstall')" class="mt-2">
                        <b-button block variant="primary" v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'replaceInstallDevice'] })">Install New Device</b-button>
                    </b-col>
                    <b-col sm="12" md="6" lg="12" v-if="showIssueToFulcrumBtn" @click="buttonPressed('issuetofulcrum')" class="mt-2">
                        <b-button block variant="primary" v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'issueToFulcrum'] })">Issue To Field</b-button>
                    </b-col>
                    <b-col sm="12" md="6" lg="12" @click="buttonPressed('addGenericTask')" class="mt-2">
                        <b-button block variant="primary">Enter Generic Task</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xs="12" lg="10" xl="10">
                <h5>Event History</h5>
                <!-- Tabbed View -->
                <b-tabs content-class="mt-3" fill >
                    <b-tab v-for="(tab, index) in tabs" :key="index" :title="tab.type">
                        <b-table small striped hover stacked="sm" :items="tab.rows" :fields="fields">
                            <template #cell(event_for)="data">
                                <span><b-link target="_blank" :to="getEventForLink(data.item)">{{data.value}}</b-link></span>
                            </template>
                            <template #cell(event_desc)="data">
                                <span v-if="data.item.aux_link != null" ><b-link target="_blank" :to="getAuxLink(data.item)">{{data.value}}</b-link></span>
                                <span v-else v-html="data.value"></span>
                            </template>
                            <template v-slot:cell(controls)="data">
                                <div>
                                    <b-button-group size="sm">
                                        <b-button 
                                            v-if="data.item.event_type == 'BFPA Test' && $store.getters.checkUIPC({path: ['viewTest', 'access'] })" 
                                            v-b-popover.hover.left="'Show ' + data.item.event_type" 
                                            class='material-icons'
                                            variant="info" 
                                            :href="getLinkTo(data.item.event_type, data.item.event_id)"
                                        >
                                            pageview
                                        </b-button>
                                        <b-button 
                                            v-if="(data.item.event_type == 'Survey' || data.item.event_type == 'Field Update') && $store.getters.checkUIPC({path: ['viewSurvey', 'access'] })" 
                                            v-b-popover.hover.left="'Show ' + data.item.event_type" 
                                            class='material-icons'
                                            variant="info" 
                                            :href="getLinkTo(data.item.event_type, data.item.event_id)"
                                        >
                                            pageview
                                        </b-button>
                                        <b-button 
                                            v-if="data.item.event_type == 'Task Comments and Flags' && $store.getters.checkUIPC({path: ['viewTaskComment', 'access'] })" 
                                            v-b-popover.hover.left="'Show ' + data.item.event_type" 
                                            class='material-icons'
                                            variant="info" 
                                            :href="getLinkTo(data.item.event_type, data.item.event_id)"
                                        >
                                            pageview
                                        </b-button>
                                        <b-button 
                                            v-if="data.item.event_type == 'BFPA Replacement' && $store.getters.checkUIPC({path: ['viewReplacementInstall', 'access'] })" 
                                            v-b-popover.hover.left="'Show ' + data.item.event_type" 
                                            class='material-icons'
                                            variant="info" 
                                            :href="getLinkTo(data.item.event_type, data.item.event_id)"
                                        >
                                            pageview
                                        </b-button>
                                        <b-button 
                                            v-if="data.item.event_type == 'Generic Task'" 
                                            v-b-popover.hover.left="'Show ' + data.item.event_type" 
                                            class='material-icons'
                                            variant="info" 
                                            :href="getLinkTo(data.item.event_type, data.item.event_id)"
                                        >
                                            pageview
                                        </b-button>
                                        <b-button v-if="data.item.event_type == 'BFPA Test' && data.item.attachment == null && $store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })"
                                            v-b-popover.hover.left="'Download Test Summary'" 
                                            class='material-icons' 
                                            variant="info" 
                                            @click="downloadPDF(data.item.event_id)"
                                        >
                                            picture_as_pdf
                                        </b-button>
                                        <b-button v-if="data.item.event_type == 'BFPA Test' && data.item.attachment != null && $store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })" 
                                            v-b-popover.hover.left="'Download Official Report'" 
                                            class='material-icons' 
                                            variant="warning" 
                                            @click="getOfficialPDF(data.item.attachment, data.item.attachment_note)"
                                        >
                                            picture_as_pdf
                                        </b-button>
                                        <b-button v-if="data.item.event_type == 'Letter Sent' && data.item.attachment != null && $store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })" 
                                            v-b-popover.hover.left="'Download Letter'" 
                                            class='material-icons' 
                                            variant="warning"
                                            @click="getOfficialPDF(data.item.attachment, data.item.attachment_note)"
                                        >
                                            picture_as_pdf
                                        </b-button>
                                    </b-button-group>
                                </div>
                            </template>
                            <template v-slot:cell(portal)="data">
                                <b-button-group size="sm">
                                    <b-button
                                        v-if="data.item.portal_tx_id != null"
                                        :variant="(data.item.portal_tx_status == 'Successful') ? 'success' : (data.item.portal_tx_status == 'Rejected') ? 'danger' : 'info'"
                                        v-b-popover.hover="data.item.portal_tx_summary"
                                        :title="'Sent Via Instant Portal: ' + data.item.portal_tx_status"
                                    >
                                        <i class="material-icons micon-1rem-icon align-middle">call_made camera</i>
                                    </b-button>
                                    <b-button
                                        v-if="data.item.portal_rx_id != null"
                                        variant="info"
                                        v-b-popover.hover="data.item.portal_rx_summary"
                                        :title="'Recieved Via Instant Portal'"
                                    >
                                        <i class="material-icons micon-1rem-icon align-middle">call_received camera</i>
                                    </b-button>
                                </b-button-group>
                            </template>
                            <template slot="event_type" slot-scope="data">
                                <a v-b-popover.hover.left="'Show ' + data.item.event_type" @click="goToLink(data.item.event_type, data.item.event_id)">{{ data.value }}</a>
                            </template>
                            <!-- <template slot="technician" slot-scope="data">
                                <a v-b-popover.hover.left="'Show Technician'" :href="`#${data.item.tech_id}`">{{ data.value }}</a>
                            </template> -->
                        </b-table>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
var fileDownload = require('js-file-download');
export default {
    name: 'eventlisttestbed',
    props: {
        parentID: {
            type: String
        },
        showTestBtn:{
            type: Boolean,
            default: () => { return false; }
        },
        showReplacementBtn:{
            type: Boolean,
            default: () => { return false; }
        },
        showInstallBtn:{
            type: Boolean,
            default: () => { return false; }
        },
        showIssueToFulcrumBtn:{
            type: Boolean,
            default: () => { return false; }
        }
    },
    components: {
    },
    data(){
        return{
            apiEndpoint: 'events/new_history/',
            fields: [
                {
                    key: 'event_type',
                    label: 'Type',
                    sortable: true
                },
                {
                    key: 'event_for',
                    label: 'On',
                    sortable: true
                },
                {
                    key: 'event_tstamp',
                    label: 'Date',
                    sortable: true,
                    formatter: 'parseReadableDate'
                },
                {
                    key: 'event_desc',
                    label: 'Result',
                    sortable: true
                },
                {
                    key: 'technician',
                    label: 'Performed By',
                    sortable: true
                },
                {
                    key: 'controls',
                    label: 'Actions',
                    sortable: false
                },
                {
                    key: 'portal',
                    label: 'Portal',
                    sortable: false
                },
            ],
            items: [],
            tabs:[]
        }
    },
    methods: {
        showData(data){
            console.log(data)
        },
        goToLink(type, id){
            if(type == 'BFPA Test'){
                this.$router.push({path: '/home/test/' + id });
            }
            else if(type == 'Survey' || type == 'Field Update'){
                this.$router.push({path: '/home/survey/' + id });
            }else if(type == 'Task Comments and Flags'){
                this.$router.push({path: '/home/comment/' + id });
            }else if(type == 'BFPA Replacement'){
                this.$router.push({path: '/home/replacement/' + id });
            }else if(type == 'Generic Task'){
                this.$router.push({path: '/home/generictask/' + id });
            }
            
        },
        getLinkTo(type, id){
            if(type == 'BFPA Test'){
                return '/home/test/' + id;
            }else if(type == 'Survey' || type == 'Field Update'){
                return '/home/survey/' + id
            }else if(type == 'Task Comments and Flags'){
                return '/home/comment/' + id
            }else if(type == 'BFPA Replacement'){
                return '/home/replacement/' + id
            }else if(type == 'Generic Task'){
                return '/home/generictask/' + id
            }
        },
        buttonPressed(eventType){
            this.$emit('eventlistbuttonpressed', {action: eventType, parentID: this.parentID});
        },
        parseReadableDate(value){
            var d = new Date(value);
            return `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2,'0')}-${d.getDate().toString().padStart(2,'0')}`
        },
        processEventDataItem(item){
            var result = item;
            const regex = /(Removal|Replacement|Install|Installation)$/gm;
            if(result.event_type.match(regex)){
                if(result.event_type == 'BFPA Installation'){
                    result.event_desc = result.event_desc + `: ${result.device_state}`;
                }else if(result.event_type == 'BFPA Replacement'){
                    result.event_desc = result.event_desc + `: ${result.aux_info}`;
                }
            }
            result.event_tstamp = this.parseReadableDate(result.event_tstamp);
            return result;
        },
        downloadPDF(eventID){
            var jwt = this.$store.getters.getJWT;
            const pdfInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            pdfInstance.get(process.env.VUE_APP_API_BASE_URL + '/download/pdf/test/' + eventID + '.pdf')
            .then(async (response) => {
                console.log(response);
                var contentDisp = response.headers["content-disposition"];
                if(contentDisp != null){
                    var fileNameIndex = contentDisp.indexOf("filename=");
                    if(fileNameIndex == -1){
                        // Doesnt Have A FileName
                        fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                    }else{
                        // Has A FileName
                        var expectedFileName = contentDisp.match(/(?<=filename=").+(?=")/g)
                        console.log(expectedFileName);
                        if(expectedFileName.length > 0){
                            fileDownload(response.data, expectedFileName[0]);
                        }else{
                            fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                        }
                    }
                }else{
                    // No File Name Specified
                    fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                }
                
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("Unable To Generate PDF Report");
                }
                console.log(error);
            });
        },
        getOfficialPDF(fileID, name){
            const fileFetchInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            fileFetchInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + fileID)
            .then(async (response) => {
                fileDownload(response.data, name);
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("File Not Found");
                }
                console.log(error);
            });
        },
        getAuxLink(item){
            var thisFormType = item.aux_link_type;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + item.aux_link
            }
        },
        getEventForLink(item){
            var thisFormType = item.aux_link_type;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + item.event_for_link
            }
        },
    },
    computed: {
    },
    mounted(){
    },
    beforeCreate(){
    },
    beforeDestroy(){
    },
    beforeMount (){
        instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.apiEndpoint + this.parentID + '.json')
            .then(async (response) => {
                //console.log("API Event List Received Response For API Endpoint Passed");
                //console.log(process.env.VUE_APP_API_BASE_URL + '' + this.apiEndpoint);
                //console.log(response.data.result);
                // Format Dates
                response.data.result.records.forEach((rec)=>{
                    this.items.push(this.processEventDataItem(rec));
                })

                var allTypes = {
                    type: "All Event Types",
                    count: this.items.length,
                    rows: this.items
                };
                this.tabs.push(allTypes);

                const regex = /(Removal|Replacement|Install)$/gm;
                
                var types = {};
                var allEventFors = _.pluck(this.items, 'event_for_link');
                if(_.uniq(allEventFors).length == 1){
                    // There is only one parent here, dont show the on value
                    this.fields.splice(1,1);
                }
                this.items.forEach((i)=>{
                    let iType = i.event_type;
                    if(i.event_type.match(regex)){
                        iType = 'Install & Replacement';
                    }
                    if(_.has(types, iType)){
                        types[iType].rows.push(i);
                        types[iType].count++;
                    }else{
                        types[iType] = {
                            type: iType,
                            count: 0,
                            rows: []
                        };
                        types[iType].rows.push(i);
                        types[iType].count++;
                    }
                });
                var keys = _.keys(types);
                keys = keys.sort();
                keys.forEach((k)=>{
                    this.tabs.push(types[k]);
                });
                //console.log("Organized Events Into Tabs");
                //console.log(this.tabs)
            })
            .catch((error) => {
                console.log(error);
            });
    },
    beforeCreate(){
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
</style>