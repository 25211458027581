<template>
    <div>
        <b-form-group
            :id="'gps-group-label-' + ownUniqueID"
            label-cols-sm="4"
            label-cols-lg="3"
            description=""
            :label-size="size"
            :label="inputLabel"
        >
            <b-row v-if="!mapOpened">
                <b-col md="2">
                    <b-button variant="info"  @click="showMap()">{{(mapOpened) ? 'Hide Map' : 'Show Map'}}</b-button>  
                </b-col>
                <b-col md="10" lg="10">
                    <b-form-group 
                        :id="'gps-latitude-label-'+ ownUniqueID"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        description=""
                        :label="latitudeLabel"
                        :label-size="size"
                        :label-for="'gps-input-latitude-'+ ownUniqueID"
                        :invalid-feedback="invalidFeedback"
                        :state="validationResult.all"
                    >
                        <b-form-input 
                            :id="'gps-input-latitude-'+ ownUniqueID"
                            v-model="inputLat"
                            type="number"
                            @input="touch($event)"
                            :required="required"
                            lazy-formatter
                            :formatter="format"
                            :state="validationResult.all"
                            :readonly="readOnly"
                            :size="size"
                            step="any"
                            :no-wheel="true"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                </b-col>
                <b-col md="10" lg="10">
                    <b-form-group 
                        :id="'gps-longitude-label-'+ ownUniqueID"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        description=""
                        :label="longitudeLabel"
                        :label-size="size"
                        :label-for="'gps-input-longitude-'+ ownUniqueID"
                        :invalid-feedback="invalidFeedback"
                        :state="validationResult.all"
                    >
                        <b-form-input 
                            :id="'gps-input-longitude-'+ ownUniqueID"
                            v-model="inputLng"
                            type="number"
                            @input="touch($event)"
                            :required="required"
                            lazy-formatter
                            :formatter="format"
                            :state="validationResult.all"
                            :readonly="readOnly"
                            step="any"
                            :size="size"
                            :no-wheel="true"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form-group> 
        <div :class="(mapOpened) ? '' : 'maphidden'">
            <b-row>
                
            </b-row>
            <b-row>
                <b-col md="12" lg="6" xl="4">
                    <b-input-group prepend="Address" class="mt-3">
                        <b-form-input v-model="gpsMapSearchAddress" @keyup.enter.native="geocode()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="info" @click="geocode()">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-row>
                        <b-col cols="6">
                            <b-input-group prepend="Latitude" class="mt-3">
                                <b-form-input disabled v-model="inputLat"></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col cols="6">
                            <b-input-group prepend="Longitude" class="mt-3">
                                <b-form-input disabled v-model="inputLng"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <div class="mt-2"></div>
                    <b-row v-if="geocodeCompleted" >
                        <b-col cols="12">
                            <b-input-group prepend="Input" class="mt-3">
                                <b-form-input disabled v-model="geocodeResult.input.formatted_address"></b-form-input>
                            </b-input-group>
                            <b-input-group prepend="Found" class="mt-3">
                                <b-form-input disabled v-model="geocodeResult.result.formatted_address"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <div class="mt-2"></div>
                    <b-row>
                        <b-col cols="12">
                            <b-button-toolbar justify>
                                <b-dropdown right text="Base Map">
                                    <b-dropdown-item @click="setMapboxStyle('Streets')">Streets</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item @click="setMapboxStyle('Satellite')">Satellite</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item @click="setMapboxStyle('Basic - Light')">Basic - Light</b-dropdown-item>
                                    <b-dropdown-item @click="setMapboxStyle('Basic - Dark')">Basic - Dark</b-dropdown-item>
                                </b-dropdown>
                                <b-button variant="warning" @click="exitMapWithoutSave()">Discard GPS Changes</b-button>
                                <b-button variant="success" @click="exitMapSaveChanges()">Save GPS Changes</b-button>
                            </b-button-toolbar>
                        </b-col>
                    </b-row>
                    
                    
                </b-col>
                <b-col md="12" lg="6" xl="8" class="my-2">
                    <div :id="'gpsinputmap-' + ownUniqueID" style="height: 50vh;width: 100%;"></div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
const cloneDeep = require('lodash.clonedeep');
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
//import { ValidationBus } from '@/validationBus.js';


export default {
    name: 'formgpsinput',
    props:{
        bus: Object,
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        latitude: {
            type: String,
            default: () => {return null}
        },
        longitude: {
            type: String,
            default: () => {return null}
        },
        latitudeLabel: {
            type: String,
            default: () => {return "Latitude"}
        },
        longitudeLabel: {
            type: String,
            default: () => {return "Longitude"}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    },
    data(){
        return{
            ownUniqueID: null,
            inputLat: '',
            inputLng: '',
            originalLat: '',
            originalLng: '',
            isDirty: false,
            mapOpened: false,
            map: null,
            gpsMapSearchAddress: null,
            marker: null,
            saveLat: null,
            saveLng: null,
            geocodeCompleted: false,
            geocodeResult: {
                input: {
                    formatted_address: null,
                    address_components: {
                        number: null,
                        street: null,
                        suffix: null,
                        city: null,
                        county: null,
                        state: null,
                        zip: null,
                        country: null
                    },
                    location: {
                        lat: null,
                        lng: null
                    }
                },
                result: {
                    formatted_address: null,
                    address_components: {
                        number: null,
                        street: null,
                        suffix: null,
                        city: null,
                        county: null,
                        state: null,
                        zip: null,
                        country: null
                    },
                    location: {
                        lat: null,
                        lng: null
                    }
                },
            }
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_longitude', value: this.inputLng, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_latitude', value: this.inputLat, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        touchWithoutDirty(){
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_longitude', value: this.inputLng, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_latitude', value: this.inputLat, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        format(value, event) {
            if(value == null || value == '')
                return null;
            else
                return value;
        },
        showMap(){
            this.saveLat = cloneDeep(this.inputLat);
            this.saveLng = cloneDeep(this.inputLng);
            if(this.mapOpened){
                this.mapOpened = false;
            }else{
                this.mapOpened = true;
                // Instantiate Map if First Time Map Instantiated
                if(this.map == null){
                    mapboxgl.accessToken = "pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ";
                    var center = [-80.720875, 28.332783];
                    var zoomSet = 12;
                    var tmpCenter = this.$store.getters.getMapDefaults;
                    if(tmpCenter != null){
                        center = [tmpCenter.longitude, tmpCenter.latitude];
                        zoomSet = tmpCenter.zoom;
                    }
                    
                    this.map = new mapboxgl.Map({
                        container: "gpsinputmap-" + this.ownUniqueID,
                        style: "mapbox://styles/trichards140/ckc2i9mdl049y1im92gdmzjsm",
                        zoom: zoomSet,
                    });
                    
                    if(this.inputLat != null && this.inputLng != null){
                        center = [this.inputLng, this.inputLat]
                    }
                    this.centerMap(center[1], center[0]);
                    this.map.on('load', ()=>{
                        this.map.resize();
                    });
                    const nav = new mapboxgl.NavigationControl();
                    this.map.addControl(nav, "top-right");

                    this.map.on('click', (e)=>{
                        this.inputLat = e.lngLat.lat;
                        this.inputLng = e.lngLat.lng;
                        this.createMarker(e.lngLat.lat, e.lngLat.lng, false);
                    });

                }else{
                    this.map.on('load', ()=>{
                        this.map.resize();
                    });
                    // this.map.resize();
                }
                // Create Marker Where Marker Is Null
                if(this.marker != null){
                    if(this.inputLat != null && this.inputLng != null){
                        this.marker.remove();
                        this.createMarker(this.inputLat, this.inputLng, true);
                    }else{
                        this.marker.remove();
                    }
                }else if(this.inputLat != null && this.inputLng != null){
                    this.createMarker(this.inputLat, this.inputLng, true);
                }
            }
        },
        centerMap(lat, lng){
            var tmpCenter = [lng, lat];
            this.map.jumpTo({
                center: tmpCenter
            });
            this.map.resize();
        },
        createMarker(lat, lng, centerMapOnMarker){
            if(this.marker != null){
                this.marker.remove();
            }
            var m = new mapboxgl.Marker()
                .setDraggable(true)
                .setLngLat([lng, lat])
                .addTo(this.map);
            this.marker = m;

            if(centerMapOnMarker){
                this.centerMap(lat, lng);
            }
            // Set Move Listener
            this.marker.on('dragend', ()=>{
                var mlatlng = this.marker.getLngLat();
                this.markerMoveListener(mlatlng.lat, mlatlng.lng);
            });
        },
        geocode(event){
            instance.post(process.env.VUE_APP_API_BASE_URL + '/atils/geocode.json', {address: this.gpsMapSearchAddress})
                .then((response) => {
                    console.log(response.data);
                    this.geocodeResult.input = response.data.result.input;
                    this.geocodeResult.result = response.data.result.result;
                    this.inputLat = this.geocodeResult.result.location.lat;
                    this.inputLng = this.geocodeResult.result.location.lng;
                    this.createMarker(this.inputLat, this.inputLng, true);
                    this.geocodeCompleted = true;
                    this.map.setZoom(18);
                    console.log(this.geocodeResult);
                })
                .catch((err)=>{
                    console.log(err);
                })
        },
        markerMoveListener(lat, lng){
            // Update The GPS Location
            console.log("Marker Location Updated")
            this.inputLat = lat;
            this.inputLng = lng;
        },
        exitMapWithoutSave(){
            this.inputLat = this.saveLat;
            this.inputLng = this.saveLng;
            this.mapOpened = false;
            this.marker.remove();
            this.map.remove();
            this.map = null;
            this.touchWithoutDirty();
        },
        exitMapSaveChanges(){
            this.mapOpened = false;
            this.marker.remove();
            this.map.remove();
            this.map = null;
            this.touch();
        },
        setMapboxStyle(sty){
            var styleURL = '';
            switch (sty) {
                case 'Satellite':
                    styleURL = 'mapbox://styles/trichards140/ckc2i9mdl049y1im92gdmzjsm';
                    break;
                case 'Streets':
                    styleURL = 'mapbox://styles/trichards140/ckc2i7b2y04671irw1c8diju1';
                    break;
                case 'Basic - Light':
                    styleURL = 'mapbox://styles/trichards140/ckc2i4uee044v1ilzl8snvp2s';
                    break;
                case 'Basic - Dark':
                    styleURL = 'mapbox://styles/trichards140/ckc2i6mvc04711im9vlsqgswk';
                    break;
                default:
                    styleURL = 'mapbox://styles/trichards140/ckc2i7b2y04671irw1c8diju1';
                    break;
            }
            this.map.setStyle(styleURL);
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
            var requiredRes = ( ((this.required && (this.inputLat == null || this.inputLng == null)) || (this.required && (this.inputLat.length == 0 || this.inputLng.length == 0))) ? false : true) ;

            var numberValid = false;
            if( !(this.inputLat == null) && !isNaN(this.inputLat) && !(this.inputLng == null) && !isNaN(this.inputLng)){
                // DB Can only handle values with ~131k digits before the decimal and ~16k digits after, we shall allow truncation of the 16,384th decimal, judgement call by TR 2020-01-08
                // This isnt the full 131,072 digits before the decimal, i consider this close enough
                if( this.inputLat < 9e131 && this.inputLng < 9e131)
                   numberValid = true;
            }else if( !this.required && !isNaN(this.inputLat) && !isNaN(this.inputLng) ){
                numberValid = true;
            }
            //console.log("Pressure Value is Valid Numerically: " + numberValid)

            //console.log("Pressure Value is Number: " + !isNaN(this.inputValue) );
            //console.log("Pressure Value is Not Null: " + !(this.inputValue == null) );
            return {requirementFilled: requiredRes, numberValid: numberValid, all: (requiredRes && numberValid)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return '';
            else if(!res.requirementFilled)
                return 'Required';
            else if(!res.numberValid)
                return 'Not A Valid Decimal Maximum Value is 9e131';
            else
                return 'OK';
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_longitude', value: this.inputLng, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName + '_latitude', value: this.inputLat, valid: this.validationResult.all, isDirty: this.isDirty} );
    },
    created(){
    },
    beforeDestroy(){
        this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName + '_longitude'} );
        this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName + '_latitude'} );
        if(this.marker != null){
            this.marker.remove();
        }
        if(this.map != null){
            this.map.remove();
        }
    },
    beforeMount (){
        this.ownUniqueID = uuidv4();
        this.originalLat = cloneDeep(this.latitude);
        this.originalLng = cloneDeep(this.longitude);
        this.inputLat = this.latitude;
        this.inputLng = this.longitude;
    },
}
</script>

<style scoped>
.maphidden{
    display: none;
}
</style>


