<template>
    <div>
        <div>
            <span :class="`section_header_${size}`">{{inputLabel}}</span>
        </div>
        <div v-if="!bypassForCount">
            <div v-for="(child, index) in inputValue" :key="child.id">
                <div class="bg-secondary text-white ml-1 mr-1 rounded clearfix">
                     <b-button class="float-left px-0 mr-1 py-1" variant="dark" v-b-toggle="'form-collapse-' + child.childFormID + '-' + index" >
                        <span class="align-middle float-left when-open">
                            <i class="material-icons drop-down">expand_more</i>
                        </span>
                        <span class="align-middle float-left when-closed">
                            <i class="material-icons drop-down">chevron_right</i>
                        </span>
                    </b-button>
                    <b-button variant="dark"
                        class="float-left px-0 mr-2 py-1" 
                        @click="child.hideFields = !child.hideFields; toggleColapseIfAppropriate(child, 'form-collapse-' + child.childFormID + '-' + index)"
                    >
                        <span class="align-middle float-left" v-if="!child.hideFields">
                            <i class="material-icons drop-down">unfold_less</i>
                        </span>
                        <span class="align-middle float-left" v-else>
                            <i class="material-icons drop-down">unfold_more</i>
                        </span>
                    </b-button>
                    <span class="float-left text-match-drop-down align-middle">{{childFormNames[index]}}</span>
                    <adv-opt-dd
                        variant="dark"
                        :changeParentCallback="()=>{ changeParent(child) }"
                        :formInspects="formInspects"
                        :inspect="child.id"
                        :formID="formID"
                    ></adv-opt-dd>
                    
                </div>
                <b-collapse v-model="child.collapseState" :id="'form-collapse-' + child.childFormID + '-' + index">
                    <b-card no-body>
                        <dyn-form 
                            :ref="child.childFormID"
                            :formID="formID" 
                            :inspectionTarget="child.id" 
                            :localFormID="child.childFormID"
                            :hideFields="child.hideFields"
                            :showBGColor="true" 
                            :isChild="true"
                            v-on:sendTitleToParent="loadChildTitle($event, index)" 
                        />
                    </b-card>
                </b-collapse>
                <b-row class="mt-2">
                    <!-- Spacer -->
                </b-row>
                <!-- <dyn-form :formID="formID" :inspectionTarget="child.id" :localFormID="child.childFormID" :showBGColor="true" v-on:sendTitleToParent="loadChildTitle($event, index)" /> -->
            </div>
            <mugen-scroll :handler="mugenDebounce" :should-handle="value != null && inputValue.length != value.length ">
                <span v-if="(value != null && inputValue.length != value.length)">Showing {{inputValue.length}} of {{(value != null ) ? value.length : '0'}} Children, Loading More... Please Be Patient</span>
            </mugen-scroll>
        </div>
        <div v-else>
            <b-button variant="warning" block disabled class="text-match-drop-down"> <b>This Record Has {{count}} {{toTitleCase(dataName)}}</b><br>This Is Too Many To Display All At Once. Try Searching Individually. </b-button>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 20000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
import MugenScroll from 'vue-mugen-scroll';

// Form Components
//import DynamicForm from "@/components/DynamicForm.vue"
import AdvancedActionsDropDown from '@/components/subcomponents/AdvActionsDropDown.vue'

export default {
    name: 'formnestedchildforms',
    components:{
        'dyn-form': () => import('../components/DynamicForm.vue'),
        'adv-opt-dd': AdvancedActionsDropDown,
        'mugen-scroll': MugenScroll
    },
    props:{
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        value: {
            type: Array,
            default: () => {return []}
        },
        readOnly: {
            type: Boolean,
            default: () => {return true} 
        },
        formID: String,
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    },
    data(){
        return{
            inputValue: [],
            inDebounce: false,
            isDirty: false,
            visibility: [],
            childFormNames: [],
            count: 0,
            bypassForCount: false,
            formInspects: null,
            mugenDebounce: null
        }
    },
    methods: {
        changeParent(child){
            this.$refs[child.childFormID][0].requestChangeParent();
        },
        toggleColapseIfAppropriate(child, collapseID){
            if(child.hideFields == false){
                if(child.collapseState == false){
                    this.$root.$emit('bv::toggle::collapse', collapseID)
                }
            }
        },
        showHideFields(index){
            // console.log(index);
            // console.log(this.inputValue[index].vis);
            this.inputValue[index].vis = !this.inputValue[index].vis;
            // console.log(this.inputValue[index].vis);
        },
        loadChildTitle: function(event, index){
            //console.log("Received Message From Child Form " + index)
            //console.log(event);
            this.childFormNames[index] = event;
            var tmp = this.childFormNames.shift();
            this.childFormNames.unshift(tmp);
        },
        getTitle(index){
            return this.childFormNames[index];
        },
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        addMoreChildren(count){
            if(count == 0){
                // No More To Add
            }else if(this.inputValue.length == this.value.length){
                // No More Can Be Added
            }else{
                // We can add more
                // Add another child from the value set
                var x = {
                    childFormID: uuidv4(),
                    id: this.value[this.inputValue.length],
                    vis: false,
                    hideFields: true,
                    collapseState: true
                };
                this.inputValue.push(x);
                this.visibility.push(false);
                this.childFormNames.push("Loading...");
                this.addMoreChildren(count - 1);
            }
        },
        mugenFetch(){
            this.addMoreChildren(5);
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            return {all:true};
        },
    },
    beforeCreate(){
        
    },
    mounted(){
        // console.log("Nested Child Form Created")
        this.$store.commit('registerFormValue', {
            parentFormID: this.parentFormID,
            type: this.$options.name, 
            dataName: this.dataName, 
            value: this.inputValue, 
            valid: true
        });
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            var split = collapseId.split("-");
            if(split != null){
                var idx = split[split.length-1]
                var childFormID = split.slice(2,-1).join("-");
                if(this.inputValue[idx] != undefined){
                    this.inputValue[idx].vis = !this.inputValue[idx].vis
                }else{
                    // This cannot be my child, I dont have enough children for this action
                }
                
            }else{
                // This isnt for me
            }
            
        })
    },
    created(){
        // Check if we can figure out what type of child this inspects
        this.formInspects = this.$store.getters.getParentTypeFromFormID({formID: this.formID})

        if(this.value != null){
            // console.log(`Called Nested Child Form With ${this.value.length} Items`)
            this.count = this.value.length;
            if(this.value.length < 5){
                this.value.forEach((child) =>{
                    var x = {
                        childFormID: uuidv4(),
                        id: child,
                        vis: false,
                        hideFields: true,
                        collapseState: true
                    };
                    this.inputValue.push(x);
                    this.visibility.push(false);
                    this.childFormNames.push("Loading...");
                })
            }else{
                this.addMoreChildren(5)
            }
            this.mugenDebounce = _.debounce(()=>{
                this.mugenFetch()
            }, 300)
        }else{
            this.mugenDebounce = ()=>{};
        }
    },
    beforeDestroy(){
        this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName} );
    },
    beforeMount (){
        
    },
}
</script>

<style scoped>
    span.section_header_sm{
        font-size: 1.25rem;
        font-weight: bold;
    }
    span.section_header_md{
        font-size: 1.5rem;
        font-weight: bold;
    }
    span.section_header_lg{
        font-size: 2.0rem;
        font-weight: bold;
    }
    .drop-down{
        font-size: 2em;
        margin-right: 0.5em;
    }
    .text-match-drop-down{
        font-size: 1.25em;
        line-height: 2.5em;
    }
    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }
    .child-form-head{
        width: 100%;
    }
</style>