<template>
<b-form-group 
    :id="'fieldset-horizontal-label-'+ parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
    <b-input-group>
        <b-form-select  
            :id="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
            v-model="inputValue"
            :options="localOptions"
            @input="touch($event)"
            :required="required"
            :state="validationResult.all"
            :readonly="readOnly"
            :multiple="multiSelect"
            :select-size="count"
            :size="size"
        >   
        </b-form-select>
        <template v-if="(prependComponent != null)" v-slot:prepend>
            <component 
                :is="prependComponent"
                :touch="prependInput"
                :size="size"
            ></component>
        </template>
        <template v-if="(appendComponent != null)" v-slot:append>
            <component 
                :is="appendComponent"
                :touch="appendInput"
                :size="size"
            ></component>
        </template>
    </b-input-group>   
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formselectpassedvaluesinput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton
    },
    props:{
        dataName: String,
        inputLabel: String,
        options: Array,
        parentFormID: String,
        multiSelect: {
            type: Boolean,
            default: () => {return false}
        },
        value: {
            type: [String, Array],
            default: (val) => {
                if(typeof val == "string")
                    return "";
                else
                    return [];
                }
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        displayCount: {
            type: Number,
            default: () => {return 1;} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    },
    data(){
        return{
            inputValue: '',
            originalValue: '',
            localOptions: [],
            isDirty: false,
            count: 1
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            // for each selected item check if the selected item is one of the options available
            // console.log(`${this.dataName} Reporting Allowed Options`);
            // console.log(this.options);
            // console.log(`${this.dataName} Reporting Selected Values`);
            // console.log(this.inputValue);
            var selectionIsInvalid = false;
            if(this.inputValue != null){
                if(this.multiSelect){
                    this.inputValue.forEach((i) =>{
                        var optMatch = this.options.filter((o)=>{ return o.value == i});
                        if(optMatch.length == 0){
                            selectionIsInvalid = true;
                        }
                    })
                }else{
                    var optMatch = this.options.filter((o)=>{ return o.value == this.inputValue});
                    if(optMatch.length == 0){
                        selectionIsInvalid = true;
                    }
                }
            }
            var match = true;
            if( selectionIsInvalid && this.required)
                match = false;
            // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
            var requiredRes = ( ((this.required && this.inputValue == null) || (this.required && this.inputValue.length == 0)) ? false : true) ;

            return {matchOptions: match, requirementFilled: requiredRes, all: (match && requiredRes)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return ''
            else if(!res.requirementFilled)
                return 'Required'
            else if(!res.matchOptions)
                return 'Invalid Entry: Not One Of The Available Options'
            else
                return 'OK'
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        if(this.multiSelect){
            if(this.displayCount == 1){
                this.count = 4;
            }else{
                this.count = this.displayCount;
            }
        }
        //console.log("Options:")
        //console.log(this.options);
        //console.log(this.value);
        var tmp;
        if(this.value == null){
            tmp = [];
        }else{
            tmp = this.value;
        }
        this.originalValue = cloneDeep(tmp);
        this.inputValue = tmp;
        this.options.forEach(itm =>{
            // console.log("Adding Option To Passed Select: " + itm.value + " " + itm.text);
            itm.disabled = false;
            this.localOptions.push(itm);
        })

        //this.localOptions = cloneDeep(tmp);
        // Add Null Selection
        if(this.required){
            this.localOptions.unshift({ value: null, text: "-- Please select an option --", disabled: true});
        }else{
            this.localOptions.unshift({ value: null, text: "-- NONE --", disabled: false});
        }
        
        
    },
}
</script>

<style>

</style>


