<template>
<b-form-group 
    :id="'fieldset-horizontal-label-'+ parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
    <b-input-group>
        <template v-if="(prependComponent != null)" v-slot:prepend>
            <component 
                :is="prependComponent"
                :touch="prependInput"
                :size="size"
            ></component>
        </template>
        <b-form-textarea
            :id="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
            v-model="inputValue"
            @input="touch($event)"
            rows="3"
            :required="required"
            :state="validationResult.all"
            :readonly="readOnly"
            :size="size"
        ></b-form-textarea>
        <template v-if="(appendComponent != null)" v-slot:append>
            <component 
                :is="appendComponent"
                :touch="appendInput"
                :size="size"
            ></component>
        </template>
    </b-input-group>
</b-form-group > 
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formtextareainput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton
    },
    props:{
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        validator: {
            type: RegExp,
            default: () => {return /.*/}
        },
        value: {
            type: String,
            default: () => {return ""}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        }
    },
    data(){
        return{
            inputValue: '',
            originalValue: '',
            isDirty: false,
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            var regexRes = false;
            if(this.inputValue.length != 0 && !this.validator.test(this.inputValue))
                regexRes = false;
            else
                regexRes = true;
            // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
            var requiredRes = ( (this.required && this.inputValue.length == 0) ? false : true) ;

            return {regexMatches: regexRes, requirementFilled: requiredRes, all: (regexRes && requiredRes)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return ''
            else if(!res.requirementFilled)
                return 'Required'
            else if(!res.regexMatches)
                return 'Invalid Entry: Input Should Match ' + this.validator
            else
                return 'OK'
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        if(this.value == null){
            this.originalValue = "";
            this.inputValue = "";
        }else{
            this.originalValue = cloneDeep(this.value);
            this.inputValue = this.value;
        }
        
    },
}
</script>

<style>

</style>


