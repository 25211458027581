<template>
<b-form-group 
    :id="'fieldset-horizontal-label-'+ parentFormID + '-' + index + '-' + dataName"
    label-cols-sm="4"
    label-cols-lg="3"
    description=""
    :label="inputLabel"
    :label-size="size"
    :label-for="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
    :invalid-feedback="invalidFeedback"
    :state="validationResult.all"
>
   
        <b-input-group class="two-by-two">
            <template v-if="(prependComponent != null)" v-slot:prepend>
                <component 
                    :is="prependComponent"
                    :touch="prependInput"
                    :size="size"
                ></component>
            </template>            
            <b-form-input
                :id="'input-horizontal-input-'+ parentFormID + '-' + index + '-' + dataName"
                v-model="inputValue"
                type="number"
                @input="touch($event)"
                :required="required"
                lazy-formatter
                :formatter="format"
                :state="validationResult.all"
                :readonly="readOnly"
                min="0"
                max="99.99"
                step="0.01"
                :no-wheel="true"
                :size="size"
            ></b-form-input>
            <template v-if="(appendComponent != null)" v-slot:append>
                <component 
                    :is="appendComponent"
                    :touch="appendInput"
                    :size="size"
                ></component>
            </template>
        </b-input-group>
  
</b-form-group > 
    
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formdecimaltwobytwoinput',
    components:{
        'btn-add': MicroArrayAddButton,
        'btn-remove': MicroArrayRemoveButton
    },
    props:{
        dataName: String,
        inputLabel: String,
        parentFormID: String,
        value: {
            type: String,
            default: () => {return null}
        },
        required: {
            type: Boolean,
            default: () => {return false} 
        },
        readOnly: {
            type: Boolean,
            default: () => {return false} 
        },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        appendComponent: {
            type: String,
            default: () => {return null} 
        },
        prependComponent: {
            type: String,
            default: () => {return null} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
    },
    data(){
        return{
            inputValue: '',
            originalValue: '',
            isDirty: false,
        }
    },
    methods: {
        touch() {
            this.isDirty = true;
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            this.$emit('onTouch', this.dataName);
        },
        appendInput(){
            if(this.appendComponent == 'btn-add'){
                // we are adding an item
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
                this.inputValue = "";
                this.isDirty = false;
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }else if(this.appendComponent == 'btn-remove'){
                // We are removing an item
                this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
                this.$store.commit('formAppend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
            }else{
                this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all, isDirty: this.isDirty} );
            }
        },
        prependInput(){
            this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index, valid: this.validationResult.all});
        },
        format(value, event) {
            if(value == null || value == '')
                return null;
            else
                return parseFloat(value).toFixed(2);
        }
    },
    computed: {
        // a computed getter
        validationResult: function () {
            var requiredRes = ( ((this.required && this.inputValue == null) || (this.required && this.inputValue.length == 0)) ? false : true) ;

            var numberValid = false;
            if( !(this.inputValue == null) && !isNaN(this.inputValue) ){
                if(this.inputValue < 100.00 && this.inputValue >= 0.00)
                   numberValid = true;
            }else if( !this.required && !isNaN(this.inputValue) ){
                numberValid = true;
            }
            //console.log("Pressure Value is Valid Numerically: " + numberValid)

            //console.log("Pressure Value is Number: " + !isNaN(this.inputValue) );
            //console.log("Pressure Value is Not Null: " + !(this.inputValue == null) );
            return {requirementFilled: requiredRes, numberValid: numberValid, all: (requiredRes && numberValid)};
        },
        invalidFeedback(){
            var res = this.validationResult;
            if(res.all)
                return '';
            else if(!res.requirementFilled)
                return 'Required';
            else if(!res.numberValid)
                return 'Not A Valid Number Maximum Value is 99.99 and Minimum Value is 0.00';
            else
                return 'OK';
        }
    },
    mounted(){
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.dataName, index: this.index, value: this.inputValue, valid: this.validationResult.all} );
    },
    created(){
    },
    beforeDestroy(){
        if(this.index == -1){
            this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName, index: this.index} );
        }
    },
    beforeMount (){
        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.value;
    },
}
</script>

<style>

</style>


