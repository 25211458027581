<template>
    <div>
        <b-dropdown
            :variant="variant"
            :class="dropdownClass"
            toggle-class="text-decoration-none" 
            no-caret
            right
        >
            <template #button-content>
                <span class="align-middle">
                    <i class="material-icons">more_vert</i>
                </span>
            </template>
            <b-dropdown-item v-if="show.changeParent" :class="itemClass"  @click="requestChangeParent()">Change Parent</b-dropdown-item>
            <b-dropdown-item v-if="show.viewInAltForm" :class="itemClass" @click="requestAlternateForm()">View In Alternate Form</b-dropdown-item>
            <b-dropdown-item v-if="availableFormOptions.length > 0" target="_blank" :href="showFormForItem()">Open In New Tab</b-dropdown-item>
        </b-dropdown>
        <b-modal
            :id="`alt-form-opening-selection-${formID}`"
            :title="`Select An Alternate Form`"
            ok-title="Open In This Form"
            cancel-title="Nevermind"
            :ok-disabled="selectedAltForm == null"
            @ok="navigateToSelectedForm"
        >
            <b-form-select v-model="selectedAltForm" :options="availableFormOptions"></b-form-select>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const uuidv4 = butils.uuidv4;

export default {
    name: 'advancedactionsdropdown',
    components:{

    },
    props:{
        dropdownClass:{
            type: String,
            default: () =>{ return 'float-right text-match-drop-down px-0 mr-1 py-1'; }
        },
        itemClass:{
            type: String,
            default: () =>{ return ''; }
        },
        changeParentCallback: Function,
        variant:{
            type: String,
            default: ()=>{ return 'secondary'}
        },
        formInspects:{
            type: String,
            default: ()=>{ return null; }
        },
        inspect:{
            type: String,
            default: ()=>{ return null; }
        },
        formID: {
            type: String,
            default: ()=>{ return uuidv4(); }
        }
    },
    data(){
        return{
            show:{
                changeParent: false,
                viewInAltForm: false
            },
            selectedAltForm: null,
            availableFormOptions: [],
        }
    },
    methods:{
        requestChangeParent(){
            if(this.changeParentCallback != null){
                this.changeParentCallback();
            }
        },
        requestAlternateForm(){
            this.$bvModal.show(`alt-form-opening-selection-${this.formID}`)
        },
        navigateToSelectedForm(){
            this.$router.push({path: `/home/form/${this.selectedAltForm}/${this.inspect}`});
        },
        showFormForItem(){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: this.formInspects});
            if(defaultFormForType != null && defaultFormForType.length > 0){
                return `/home/form/${defaultFormForType[0].id}/${this.inspect}`
            }
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
    },
    mounted(){
        if(this.formInspects != null && this.inspect != null){
            this.show.viewInAltForm = true;
            // var multiForm = this.$store.getters.getFullStackForm({inspecting: this.formInspects});
            var allForType = this.$store.getters.getEveryAvailableFormForGivenInspectionType({inspecting: this.formInspects});
            //var all = allForType.concat(multiForm)
            allForType.forEach((form)=>{
                if(form.notes != null){
                    this.availableFormOptions.push({
                        text: `${form.name} - ${form.notes}`,
                        value: form.id
                    })
                }else{
                    this.availableFormOptions.push({
                        text: `${form.name}`,
                        value: form.id
                    })
                }
            })
        }
        if(this.changeParentCallback != null){
            this.show.changeParent = true;
        }
        
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>

</style>